import React, { useEffect, useContext } from 'react';
import useSetState from '../../utils/useSetState';
import fetch_data from './utils/fetch_data';
import fetchUserDecks from './utils/fetchUserDecks';
import onRemoveHandler from './utils/onRemoveHandler';
import updateItemInDecks from './utils/updateItemInDecks';
import DeckItem from '../DeckItem';
import queryString from 'query-string';
import { UserContext } from '../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../Context/FeedbackContext/FeedbackContext';
import { cloneDeep } from 'lodash';

const DeckItemPageContainer = ({ location }) => {
  const [state, setState] = useSetState({ isLoading: true });

  const { userGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  const deckName = queryString.parse(location.search).deckName;
  const deckId = queryString.parse(location.search).deckId;
  const artistName = queryString.parse(location.search).by;

  const deckItem = state && state.deckItem;

  const crumbsArr = deckItem
    ? // It's easier to use the deckName on the query string, otherwise, if we used the user on context we would need to check if the user own the item and would cause flickers when te user refresh or loaded the item page directly
      deckName
      ? [
          {
            title: 'My Decks',
            to: `/dashboard/myDecks?artistId=${deckItem.artist}`
          },
          { title: deckName, to: `/deck?_id=${deckId}` },
          { title: 'Item' }
        ]
      : [
          { title: 'Public Artworks', to: '/#public-artworks' },
          { title: 'Item' }
        ]
    : [];

  useEffect(() => {
    const queryObj = queryString.parse(location.search);
    const id = queryObj['_id'];
    fetch_data({ setState, id });
  }, []);

  const onModalLoad = async () => {
    setState({ isLoading: true });
    const userDecks = await fetchUserDecks({
      artistId: userGlobalState.artist._id
    });
    setState({ userDecks, isLoading: false });
  };

  const onDeckInsertListChange = (newDeckInsertList) => {
    setState({ deckInsertList: cloneDeep(newDeckInsertList) });
  };

  return (
    <DeckItem
      deckItem={deckItem}
      deckName={deckName}
      artistName={artistName}
      deckId={deckId}
      crumbsArr={crumbsArr}
      state={state}
      onSubmit={updateItemInDecks.bind(null, {
        state,
        deckItem,
        userGlobalState,
        setFeedbackState
      })}
      setState={setState}
      userGlobalState={userGlobalState}
      onModalLoad={onModalLoad}
      userDecks={state.userDecks}
      onDeckInsertListChange={onDeckInsertListChange}
      onRemoveHandler={onRemoveHandler.bind(null, { state, setFeedbackState })}
    />
  );
};

export default DeckItemPageContainer;
