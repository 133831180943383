
import React from "react";
// import { UserContext } from '../containers/UserContext/UserContext';
import DeckItemPageContainer from '../DeckItem/containers/DeckItemPageContainer';


const DeckItem = ({ location }) => {

  // const { userGlobalState, setUserGlobalState } = useContext(UserContext);

  return (
    <DeckItemPageContainer location={location}/>
  )
}

export default DeckItem;
