import React from 'react';
import { Link } from 'gatsby';
import Navbar from '../lib/components/Navbar/Navbar';
import CenterContainer from '../lib/components/CenterContainer/CenterContainer';
import GridSkeletons from '../lib/components/GridSkeletons/GridSkeletons';
import styles from './DeckItem.module.css';
import Breadcrumbs from '../lib/components/Breadcrumbs/Breadcrumbs';
import { Modal, Tooltip } from '@material-ui/core';
import AddToDeckSection from '../lib/components/AddToDeckSection/AddToDeckSection';
import Button from '../lib/components/Button/Button';
import stringifyTimestamp from '../utils/stringifyTimestamp';
import { Edit, GetApp, Delete } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

const DeckItem = ({
  crumbsArr,
  deckItem,
  state,
  setState,
  onSubmit,
  userGlobalState,
  onModalLoad,
  userDecks,
  onDeckInsertListChange,
  onRemoveHandler,
  deckName,
  deckId,
  artistName
}) => {
  return (
    <div>
      <Helmet>
        <title>Deck Item</title>
      </Helmet>
      <Navbar />
      <CenterContainer>
        <div className={styles.topContainer}>
          {deckItem ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <div className={styles.breadcrumbs__wrap}>
                    <Breadcrumbs crumbsArr={crumbsArr} />
                  </div>
                  <h2 className={styles.title}>{deckItem.title}</h2>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '13px',
                      margin: '8px 0px 15px 0px',
                      color: '#c4c4c4'
                    }}
                  >
                    <span>
                      {stringifyTimestamp({ timestamp: deckItem.createdAt })}
                    </span>
                    <span style={{ margin: '0px 5px' }}>|</span>
                    <span>
                      by{' '}
                      <Link
                        to={`/artist?_id=${deckItem.artist}`}
                        style={{
                          textDecoration: 'underline',
                          color: 'inherit',
                          cursor: 'pointer'
                        }}
                      >
                        {artistName}
                      </Link>
                    </span>
                    <span style={{ margin: '0px 5px' }}>|</span>
                    <span>{roundToTwo(deckItem.fileSize / 1000000)} MB</span>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <br />
          {deckItem ? (
            <div className={styles.bodyContainer}>
              <div className={styles.imgContainer}>
                <img
                  className={styles.ItemImage}
                  src={deckItem.imgUrl}
                  // style={{backgroundImage: `url("${deckItem.imgUrl}")`}}
                />

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    marginTop: '12px'
                  }}
                >
                  <div className={styles.buttonsBox}>
                    {userGlobalState && userGlobalState.artist ? (
                      <Button
                        onClick={setState.bind(null, { isModalOpen: true })}
                      >
                        Add to Deck
                      </Button>
                    ) : null}
                  </div>
                  {userGlobalState &&
                  userGlobalState.artist &&
                  deckItem &&
                  deckItem.artist == userGlobalState.artist._id ? (
                    <div className={styles.iconsBar}>
                      <Link
                        to={
                          deckItem.isCopy
                            ? null
                            : `/dashboard/items/update?itemId=${deckItem._id}&by=${artistName}` +
                              (deckId && deckName
                                ? `&deckName=${deckName}&deckId=${deckId}`
                                : '')
                        }
                      >
                        {deckItem.isCopy ? (
                          <Tooltip title="This item is a copy and can't be edited">
                            <Edit fontSize="inherit" />
                          </Tooltip>
                        ) : (
                          <Edit fontSize="inherit" />
                        )}
                      </Link>

                      <div onClick={onRemoveHandler}>
                        <Delete fontSize="inherit" />
                      </div>
                      <a target="_blank" href={deckItem.fileUrl}>
                        <GetApp fontSize="inherit" />
                      </a>
                    </div>
                  ) : null}
                </div>

                <div className={styles.infoContainer}>
                  <div className={styles.infoContainer_texts}>
                    <h2 className={styles.infoContainer__descriptionTitle}>
                      Description
                    </h2>
                    <div className={styles.description}>
                      <p>{deckItem.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <GridSkeletons />
          )}
          <Modal
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            open={state.isModalOpen}
            onClose={setState.bind(null, { isModalOpen: false })}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={styles.modal}>
              <AddToDeckSection
                deckItem={deckItem}
                onModalLoad={onModalLoad}
                onSubmit={onSubmit}
                userGlobalState={userGlobalState}
                userDecks={userDecks}
                onChange={onDeckInsertListChange}
                isLoading={state.isLoading}
              />
            </div>
          </Modal>
        </div>
      </CenterContainer>
      <br />
      <br />
      <br />
    </div>
  );
};

export default DeckItem;

const roundToTwo = (num) => {
  return +(Math.round(num + 'e+2') + 'e-2');
};
