
import { api_base_url } from '../../../../global_data';
import axios from "axios";


const fetchUserDecks = async ({ artistId }) => {
    
    const res = await axios({
        url: `${api_base_url}/decks/filter?filterBy=artist&filterVal=${artistId}`,
        method: 'GET'
    });

    return res.data;

}

export default fetchUserDecks;