import React, { useEffect } from 'react';
import useSetState from '../../../utils/useSetState';
import { cloneDeep } from 'lodash';
import styles from './AddToDeckSection.module.css';
import Button from '../../components/Button/Button';
import Checkbox from '@material-ui/core/Checkbox';

const AddToDeckSection = ({
  deckItem,
  onSubmit,
  userGlobalState,
  onModalLoad,
  userDecks,
  onChange,
  isLoading
}) => {
  const [state, setState] = useSetState({ deckInsertList: {} });

  const isItemOwner = deckItem.artist == userGlobalState.artist._id;

  useEffect(() => {
    onModalLoad();
  }, []);

  useEffect(() => {
    onChange(state.deckInsertList);
  }, [state.deckInsertList]);

  useEffect(() => {
    const deckIsertStatus = getInitialDeckInsertStatus({ userDecks, deckItem });
    setState({ deckInsertList: deckIsertStatus });
  }, [JSON.stringify(userDecks)]);

  const changeDeckInsertStatus = (deckId) => {
    const deckInsertListClone = cloneDeep(state.deckInsertList);
    deckInsertListClone[deckId] = !deckInsertListClone[deckId];
    setState({ deckInsertList: deckInsertListClone });
  };

  return userDecks && state.deckInsertList && !isLoading ? (
    userDecks.length == 0 ? (
      'You have no decks yet'
    ) : (
      <div>
        <div className={styles.itemInfo}>
          <div className={styles.itemInfo__left}>
            {/* <div
              style={{ backgroundImage: `url("${deckItem.imgUrl}")` }}
              className={styles.deckImg}
            >
            </div>
            <h2>{deckItem.title}</h2> */}
            <p className={styles.infoNote}>
              {isItemOwner
                ? 'Manage in which decks your item will appear'
                : 'This item belongs to another artist, by submiting you are creating a new copy of the item and adding that copy to each Deck of your chosing'}
            </p>
          </div>
          <div className={styles.itemInfo__right}>
            <Button onClick={onSubmit} type="submit">
              {isItemOwner ? 'Save' : 'Copy'}
            </Button>
          </div>
        </div>
        <div className={styles.deckOptions__optionsWrapper}>
          {userDecks.map((deck) => {
            return (
              <div
                className={styles.deckOptions__optionWrapper}
                key={'userDecks-' + deck._id}
              >
                <Checkbox
                  checked={state.deckInsertList[deck._id] ? true : false}
                  onChange={changeDeckInsertStatus.bind(null, deck._id)}
                  // defaultChecked={changeDeckInsertStatus.bind(null, deck._id)}
                  type="checkbox"
                  size="big"
                  color="primary"
                />
                <span>{deck.title}</span>
              </div>
            );
          })}
        </div>
      </div>
    )
  ) : (
    'Loading...'
  );
};

export default AddToDeckSection;

const getInitialDeckInsertStatus = ({ userDecks, deckItem }) => {
  const result = {};

  if (userDecks && userDecks.length > 0) {
    userDecks.forEach((deck) => {
      if ((deck.deckItems || []).includes(deckItem._id)) {
        result[deck._id] = true;
      }
    });
  }

  return result;
};
