import { api_base_url } from '../../../../global_data';
import axios from 'axios';
import { navigate } from 'gatsby';

const onRemoveHandler = async ({ state, setFeedbackState }) => {
  try {
    if (
      window.confirm(
        'Please confirm the permanent removal of this item from your account (it will disappear from all decks)'
      )
    ) {
      const { deckItem } = state;

      setFeedbackState({
        isActive: true,
        isLoading: true
      });

      await axios({
        url: `${api_base_url}/deckItems/${deckItem._id}`,
        method: 'DELETE'
      });

      // we can't redirect to the deck page because when we remove via public link there is no deck id there to use here
      navigate(`/dashboard/myDecks?artistId=${deckItem.artist}`);

      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: false,
        message: 'Deck Item Removed'
      });
    }
  } catch (err) {
    console.log('err', err);
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: 'Error'
    });
  }
};

export default onRemoveHandler;
