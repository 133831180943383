
import { api_base_url } from '../../../../global_data';
import axios from "axios";


const updateItemInDecks = async ({ state, deckItem, userGlobalState, setFeedbackState }, e) => {
    
    try {

        setFeedbackState({
            isActive: true,
            isLoading: true
        })

        const { deckInsertList, userDecks } = state;
        const userArtist = userGlobalState.artist;

        e.preventDefault();
    
        await axios({
            url: `${api_base_url}/decks/updateItemInDecks`,
            method: 'POST',
            headers: {
                "Content-type": "application/json; charset=utf-8"
            },
            data: JSON.stringify({deckItem, deckInsertList, userDecks, userArtist})
        });

        setFeedbackState({
            isActive: true,
            isError: false,
            isLoading: false,
            message: 'Decks updated'
        })

    } catch (err) {
        console.log("err", err)
        setFeedbackState({
            isActive: true,
            isError: true,
            isLoading: false,
            message: err.response.data || 'Error'
        })
    }
    

}

export default updateItemInDecks;